<template>
    <div class="container">
        <div class="field">
            <label class="label">JSON</label>
            <div class="control has-icons-left">
                <textarea class="textarea" placeholder="10 lines of textarea" rows="20" v-model="json"></textarea>
                <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                </span>
            </div>
        </div>
        <div class="field">
            <p class="control">
                <button @click="sendRequest()" class="button is-success">
                Send
                </button>
            </p>
        </div>
        <div v-if="response" class="card">
            <div class="card-content">
                <div class="content">
                    {{ response }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
    name: 'Postman',
    data() {
        return {
            json: '',
            response: null
        }
    },
    methods: {
        sendRequest() {
            this.response = null
            const obj = JSON.parse(this.json)
            const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
            masterFunction(obj).then(result => {
                this.response = result
            }).catch(err => {
                this.response = err
            })
        }
    },
    mounted() {
        if (localStorage.monabrunJson) {
            this.json = localStorage.monabrunJson;
        }
    },
    watch: {
        json(newJson) {
            localStorage.monabrunJson = newJson;
        }
    }
}
</script>
